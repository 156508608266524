<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            Negative keywords and approved booking details
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-switch v-model="showFilterKWA" label="Show keyword filters" @change="showFilterKWAChanged"></v-switch>
          </v-col>
          <v-col>
            <v-switch v-model="showFilterVP" label="Show verticals &amp; providers filters" @change="showFilterVPChanged"></v-switch>
          </v-col>
          <v-col>
            <v-switch v-model="showFilterTer" label="Show territories filters" @change="showFilterTerChanged"></v-switch>
          </v-col>
          <v-col>
            <v-btn class="mt-2" color="primary" @click="search">Search</v-btn>
          </v-col>
        </v-row>

        <v-row v-show="showFilterKWA">
          <v-col>
            <v-text-field v-model="keywordText" label="Exact keyword text" clearable prepend-icon="text_snippet">
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field v-model="keywordSearch" label="Part of keyword" clearable prepend-icon="text_snippet">
            </v-text-field>
          </v-col>

          <v-col>
            <v-autocomplete v-model="selectedMatchTypes" :items="matchTypes" multiple label="Match types" clearable prepend-icon="format_size">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete v-model="selectedKeywordTypes" :items="keywordTypes" multiple label="Keyword types" clearable item-text="name" item-value="name"
              prepend-icon="remove_circle_outline">
              <template v-slot:item="data">
                <v-list-item-action>
                  <v-icon v-if="data.attrs.inputValue">check_box</v-icon>
                  <v-icon v-else>check_box_outline_blank</v-icon>
                </v-list-item-action>
                <v-list-item-icon>
                  <v-icon>{{data.item.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-4">
                  <v-list-item-title v-text="data.item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row v-show="showFilterVP">
          <v-col>
            <v-autocomplete v-model="selectedVerticals" :items="activeVerticals" item-text="name" item-value="name" multiple label="Verticals" 
              clearable prepend-icon="construction">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete v-model="selectedProviders" :items="activeProviders" item-text="name" item-value="name" multiple label="Providers" 
              clearable prepend-icon="business">
            </v-autocomplete>
          </v-col>

          <v-col>
          </v-col>

          <v-col>
          </v-col>
        </v-row>

        <v-row v-show="showFilterTer">
          <v-col>
            <v-autocomplete v-model="selectedRegions" :items="regions" item-text="name" item-value="name" multiple label="Regions" 
              clearable prepend-icon="dashboard">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete v-model="selectedCountries" :items="activeCountries" item-text="name" item-value="name" multiple label="Countries" 
              clearable prepend-icon="public">
            </v-autocomplete>
          </v-col>

          <v-col>
          </v-col>

          <v-col>
          </v-col>
        </v-row>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-1"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`header.keyword_type`]="{ header }">
        <v-tooltip>
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on">help</v-icon>
          </template>
          <span>
            <v-icon color="white">copyright</v-icon> is Brand, <v-icon color="white">sports_kabaddi</v-icon> is Competitor, <v-icon color="white">speed</v-icon> is Performance
          </span>
        </v-tooltip>
        {{ header.text }}
      </template>

      <template v-slot:[`item.keyword_type`]="{ item }">
        <v-icon>{{ nkw_getTypeIcon(item.keyword_type) }}</v-icon>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon :to="{ name: 'Negative keyword', params: { nkwID: item.id }}">
          <v-icon small>edit</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'
import nkwMixin from '@/mixins/negativeKeywords'

export default {
  name: 'NegativeKeywordsTable',

  data () {
    return {
      headers: [
        { text: 'Keyword', value: 'notated_keyword' },
        { text: 'Type', value: 'keyword_type' },
        { text: 'Verticals', value: 'vertical_short_names_csv' },
        { text: 'Providers', value: 'providers_csv' },
        { text: 'Territories', value: 'territories_csv' },
        { text: '# comments', value: 'comments_count' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      showFilterKWA: false,
      showFilterVP: false,
      showFilterTer: false,
      keywordText: '',
      keywordSearch: '',
      selectedMatchTypes: [],
      selectedKeywordTypes: [],
      selectedProviders: [],
      selectedVerticals: [],
      selectedRegions: [],
      selectedCountries: [],
      lsOptions: 'negativeKeywords_options',
      lsFilters: 'negativeKeywords_filters',
      dataEndpoint: '/a/nkw/keywords',
    }
  },

  mixins: [dataTablesMixin, nkwMixin],

  computed: {
    activeCountries () {
      return this.$store.state.common.activeCountries
    },
    activeProviders () {
      return this.$store.state.core.activeProviders
    },
    activeVerticals () {
      return this.$store.state.core.activeVerticals
    },
    keywordTypes () {
      return this.$store.state.nkw.keywordTypes
    },
    matchTypes () {
      return this.$store.state.core.matchTypes
    },
    regions () {
      return this.$store.state.common.regions
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    fetchData: function () {
      this.loading = true
      var myURL = this.dataEndpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['actions'], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // filter keyword text
      if (this.keywordText) {
        myURL += '&keyword=' + this.keywordText
      }

      // filter keyword search
      if (this.keywordSearch) {
        myURL += '&keyword=~' + this.keywordSearch + '~'
      }

      // filter match types
      if (this.selectedMatchTypes.length > 0) {
        myURL += '&match_type=' + this.selectedMatchTypes.join('|')
      }

      // filter NKW types
      if (this.selectedKeywordTypes.length > 0) {
        myURL += '&keyword_type=' + this.selectedKeywordTypes.join('|')
      }

      // filter verticals
      if (this.selectedVerticals.length > 0) {
        myURL += '&verticals_csv=~[' + this.selectedVerticals.join('|') + ']~'
      }

      // filter providers
      if (this.selectedProviders.length > 0) {
        myURL += '&providers_csv=~[' + this.selectedProviders.join('|') + ']~'
      }

      // filter regions
      if (this.selectedRegions.length > 0) {
        myURL += '&regions_csv=~[' + this.selectedRegions.join('|') + ']~'
      }

      // filter countries
      if (this.selectedCountries.length > 0) {
        myURL += '&countries_csv=~[' + this.selectedCountries.join('|') + ']~'
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    search: function () {
      let filterObj = {
        'showFilterKWA': this.showFilterKWA,
        'showFilterVP': this.showFilterVP,
        'showFilterTer': this.showFilterTer,
        'keywordText': this.keywordText,
        'keywordSearch': this.keywordSearch,
        'selectedMatchTypes': this.selectedMatchTypes,
        'selectedKeywordTypes': this.selectedKeywordTypes,
        'selectedVerticals': this.selectedVerticals,
        'selectedProviders': this.selectedProviders,
        'selectedRegions': this.selectedRegions,
        'selectedCountries': this.selectedCountries,
      }
      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },

    showFilterKWAChanged () {
      if (!this.showFilterKWA) {
        this.keywordSearch = ''
        this.selectedMatchTypes = []
        this.selectedKeywordTypes = []
        this.search()
      }
    },

    showFilterVPChanged () {
      if (!this.showFilterVP) {
        this.selectedVerticals = []
        this.selectedProviders = []
        this.search()
      }
    },

    showFilterTerChanged () {
      if (!this.showFilterVP) {
        this.selectedRegions = []
        this.selectedCountries = []
        this.search()
      }
    }
  },

  created: function () {
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['showFilterKWA']) { this.showFilterKWA = filters['showFilterKWA'] }
      if (filters['showFilterVP']) { this.showFilterVP = filters['showFilterVP'] }
      if (filters['showFilterTer']) { this.showFilterTer = filters['showFilterTer'] }

      if (filters['keywordText']) { this.keywordText = filters['keywordText'] }
      if (filters['keywordSearch']) { this.keywordSearch = filters['keywordSearch'] }
      if (filters['selectedMatchTypes']) { this.selectedMatchTypes = filters['selectedMatchTypes'] }
      if (filters['selectedKeywordTypes']) { this.selectedKeywordTypes = filters['selectedKeywordTypes'] }

      if (filters['selectedVerticals']) { this.selectedVerticals = filters['selectedVerticals'] }
      if (filters['selectedProviders']) { this.selectedProviders = filters['selectedProviders'] }

      if (filters['selectedRegions']) { this.selectedRegions = filters['selectedRegions'] }
      if (filters['selectedCountries']) { this.selectedCountries = filters['selectedCountries'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
