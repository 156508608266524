export default {
  data () {
    return {
      datatables_booleanItems: [
        { id: 'true', name: 'Yes' },
        { id: 'false', name: 'No' },
      ],
      // how often each datatable will be automatically refreshed, in milliseconds
      datatables_refreshIntervalMs: 60000,
      datatables_rowsPerPageItems: [10,20,30,50,100],
    }
  },

  methods: {
    datatables_addHeaderFieldsToURI: function (uri = '', headers = [], excludedFields = [], addedFields = []) {
      // add fields from headers, except fields which are in the excluded array
      for (var i = 0; i < headers.length; i++) {
        if (excludedFields.includes(headers[i].value)) {
          continue
        }
        uri += headers[i].value + ','
      }

      // add fields from addedFields array
      for (var j = 0; j < addedFields.length; j++) {
        uri += addedFields[j] + ','
      }

      // trim final comma and return
      return uri.substring(0, uri.length - 1)
    },

    datatables_footerPageText (totalItemsIsEstimate, estimatedItems, props) {
      // if totalItems is an estimate, show estimatedItems, not props.itemsLength (= totalItems)
      // indicate it is an estimate with "~"
      // see comment in datatables_getItemCounts for the reason why totalItems is not used
      if (totalItemsIsEstimate) {
        return props.pageStart + '-' + props.pageStop + ' of ~' + estimatedItems
      }
      return props.pageStart + '-' + props.pageStop + ' of ' + props.itemsLength
    },

    datatables_getItemCounts (totalItemsIsEstimate, totalItems) {
      // if totalItems is an estimate, replace it with a high number so that the next page button is always shown
      // this is to allow proper pagination when, for example, the estimated totalItems is 8, but there are actually 12 records
      // and when the user is showing 10 records per page, the next page button would normally be disabled
      if (totalItemsIsEstimate) {
        return {'totalItems': 1000000, 'estimatedItems': totalItems}
      }
      return {'totalItems': totalItems, 'estimatedItems': 0}
    },

    datatables_formatTimestamp (sqlTimestamp) {
      const ts = this.$dateTime.fromSQL(sqlTimestamp)
      return ts.toLocaleString(this.$dateTime.DATETIME_MED)
    },

    // handle translate datatable pagination and sorting into URL params for the API
    // uri = the API uri before pagination and sorting are added
    // options = the options object from v-data-table
    // NB: default sorting is handled by API and not needed here
    datatables_processURIOptions: function (uri = '', options = {}) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      // current page
      if (page > 0) {
        uri += '&xpage=' + page
      } else {
        uri += '&xpage=1'
      }

      // num items per page
      if (itemsPerPage > 0) {
        uri += '&xper_page=' + itemsPerPage
      } else {
        uri += '&xper_page=10'
      }

      // sorting: both sortBy and sortDesc are arrays
      // if no sorting was sent by datatable, use defaultSort
      var sortParam = ''
      for (var i = 0; i < sortBy.length; i++) {
        if (i === 0) {
          sortParam = '&xsort='
        } else {
          sortParam += ','
        }

        if (sortDesc[i]) {
          sortParam += '-' + sortBy[i]
        } else {
          sortParam += sortBy[i]
        }
      }
      uri += sortParam

      return uri
    },
  },
}
