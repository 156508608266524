export default {
  methods: {
    nkw_getActionIcon (action) {
      switch (action) {
        case 'Add':
          return 'add'
        case 'Remove':
          return 'clear'
        default:
          return 'help'
      }
    },

    nkw_getApprovalStatusClass (approvalStatus) {
      switch (approvalStatus) {
        case 'Declined':
          return 'declined'
        case 'Under review':
          return 'under-review'
        default:
          return ''
      }
    },

    nkw_getApprovalStatusIcon (approvalStatus) {
      switch (approvalStatus) {
        case 'Approved':
          return 'done'
        case 'Declined':
          return 'close'
        case 'Under review':
          return 'grading'
        default:
          return 'help'
      }
    },

    nkw_getTypeIcon (keywordType) {
      switch (keywordType) {
        case 'Brand':
          return 'copyright'
          case 'Competitor':
            return 'sports_kabaddi'
          case 'Performance':
          return 'speed'
        default:
          return 'help'
      }
    }
  }
}